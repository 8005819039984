import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Label from '../Label';
import Button from '../Button';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import BannerOne from '../BannerOne';
import CancelButton from '../CancelButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { DynamicImage, Loader } from 'components';
import { getLocalisedProduct } from 'utils/localization';
import { updateLead, updateQuizAnswers } from 'state/user/effects';

interface HardcoverProps {}

const PaymentStepsStyled = styled(PaymentSteps)`
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem;
  width: 100%;
`;

const LabelStyled = styled(Label)`
  text-align: center;
  font-family: Manrope;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 0.75rem;
  @media ${tablet} {
    font-size: 1rem;
    margin-top: 1rem;
  }
`;

const ImageContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;

const ImageSectionStyled = styled(ImageSection)<{ width: string }>`
  width: 100%;
  max-width: 21.53125rem;
  margin-bottom: 2.5rem;
  @media ${tablet} {
    max-width: 16.34375rem;
    margin-bottom: 1.5rem;
  }
`;

const ImageSectionStyledBottom = styled(ImageSectionStyled)`
  width: 100%;
  max-width: 21.53125rem;
  margin-bottom: 0;
  @media ${tablet} {
    max-width: 16.34375rem;
  }
`;

const HeadlineBiggerContainer = styled.div`
  width: 100%;
  padding: 1rem 1rem 0;
  @media ${tablet} {
    padding-bottom: 0;
  }
  @media ${mobile} {
    max-width: 21.3rem;
  }
`;

const HeadlineBigger = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.032rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 150%;
    letter-spacing: -0.0165rem;
  }
`;

const HeadlineSmaller = styled.p`
  color: #363648;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.028rem;
  margin-top: 5.5rem;
  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 150%;
    letter-spacing: -0.01375rem;
    margin-top: 1.5rem;
  }
`;

const CancelButtonStyled = styled(CancelButton)`
  margin-bottom: 3rem;
`;

const BookContainer = styled.div`
  position: relative;
  max-width: 21.4rem;
  margin-top: 3rem;
`;

const BookCover = styled(DynamicImage)`
  width: 21.4rem;
  height: 18.8rem;
  left: -25px;
`;

const BabyName = styled.p<{ bookColor: string }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-family: 'Brandon Grotesque', sans-serif;
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 1.418rem;
  text-transform: uppercase;
  position: absolute;
  top: 3.5rem;
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const BottomNames = styled.p<{ bookColor: string; isLongText: boolean }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-size: ${({ isLongText }) => (isLongText ? '0.435rem' : '0.435rem')};
  font-weight: 700;
  line-height: 0.5rem;
  text-transform: uppercase;
  position: absolute;
  bottom: ${({ isLongText }) => (isLongText ? '4rem' : '4.5rem')};
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;
  font-family: 'Brandon Grotesque', sans-serif;
  text-align: center;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const Hardcover: FC<HardcoverProps> = ({
  bannerOne,
  paymentSteps,
  headline,
  planSection,
  PlanSectionComponent,
  headline2,
  headline3,
  label,
  imageSection2,
  imageSection,
  button,
  loading,
  handleUpgradeClick,
  localisedProduct,
  userHasBoughtPaperbackBook,
  handleCancelClick,
  cancelButton,
}) => {
  const { isTablet } = useQuery();
  const { quiz_answers, code } = useSelector((state: AppState) => state.user);
  const [showName, setShowName] = useState<boolean>(false);
  const dispatch = useDispatch();
  if (!localisedProduct) {
    return null;
  }
  const localisedProductMain = getLocalisedProduct(localisedProduct);

  const upgradeHandler = () => {
    if (
      localisedProductMain?.key === 'hardcover-book-upsell' ||
      localisedProductMain?.key === 'hardcover-book-upsell-v2'
    ) {
      dispatch(updateQuizAnswers({ hardCover: true }));
      dispatch(
        updateLead(code, {
          quiz_answers: { ...quiz_answers, hardCover: true },
        }),
      );
    }
    handleUpgradeClick?.();
  };

  const renderNames = () => {
    const { mom_name, dad_name, baby_name, parenting_situation } =
      quiz_answers || {};

    let namesString = '';

    switch (parenting_situation) {
      case 'mom':
        namesString = `${mom_name} & ${baby_name}`;
        break;
      case 'dad':
        namesString = `${dad_name} & ${baby_name}`;
        break;
      default:
        namesString = `${mom_name}, ${dad_name} & ${baby_name}`;
        break;
    }

    const isLongText = namesString.length > 35;

    if (
      (isLongText && parenting_situation === 'parents') ||
      (isLongText && parenting_situation.includes('two'))
    ) {
      namesString = namesString.replace(' & ', ' &<br/> ');
    }

    return { namesString, isLongText };
  };

  const { namesString, isLongText } = renderNames();

  const handleOnLoad = () => {
    setShowName(true);
  };

  return (
    <>
      {bannerOne && (
        <BannerOne
          {...bannerOne}
          boughtPaperback={userHasBoughtPaperbackBook}
        />
      )}

      <PaymentStepsStyled
        {...paymentSteps}
        hasOptionalStep={userHasBoughtPaperbackBook}
      />

      <HeadlineBiggerContainer>
        <HeadlineBigger>{headline}</HeadlineBigger>
      </HeadlineBiggerContainer>
      <BookContainer>
        <BookCover
          alt="Baby book cover"
          src={`books-mockups/${quiz_answers?.color}_${quiz_answers?.icon}.png`}
          handleOnLoad={handleOnLoad}
        />
        {showName && (
          <>
            <BabyName bookColor={quiz_answers?.color}>
              {quiz_answers?.baby_name}
            </BabyName>
            <BottomNames
              bookColor={quiz_answers?.color}
              isLongText={isLongText}
              dangerouslySetInnerHTML={{ __html: namesString }}
            />
          </>
        )}
      </BookContainer>
      <PlanSectionComponent {...planSection} />
      <HeadlineSmaller>{headline2}</HeadlineSmaller>
      <HeadlineBigger>{headline3}</HeadlineBigger>
      <LabelStyled {...label} align={'center'} />
      <ImageContainer>
        <DynamicImage src={imageSection2.image} alt="Baby books" />
      </ImageContainer>
      {loading ? (
        <Loader wrapperHeight={'1'} />
      ) : (
        <Button
          bgColor="primary"
          disabled={loading}
          onClick={upgradeHandler}
          {...button}
          secondaryText={`${button.secondaryText} ${localisedProduct?.currency}${localisedProduct?.finalPrice}`}
        >
          {button.text}
        </Button>
      )}
      <CancelButtonStyled
        disabled={loading}
        onCancelClick={handleCancelClick}
        noButtonTitle={cancelButton.noButtonTitle}
      />
    </>
  );
};

export default Hardcover;
